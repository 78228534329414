import { render, staticRenderFns } from "./KPaginationList.vue?vue&type=template&id=30d63ba0&scoped=true&"
import script from "./KPaginationList.vue?vue&type=script&lang=ts&"
export * from "./KPaginationList.vue?vue&type=script&lang=ts&"
import style0 from "./KPaginationList.vue?vue&type=style&index=0&id=30d63ba0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d63ba0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KPagination: require('/tmp/build_acca07ff/apps/web/components/pagination/KPagination.vue').default})
